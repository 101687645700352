.banner {
  display: flex;
  position: relative;
  flex-direction: column;
}

.banner__box {
  text-align: center;
}

@media only screen and (max-width: 749px) {
  .banner--content-align-mobile-right .banner__box {
    text-align: right;
  }

  .banner--content-align-mobile-left .banner__box {
    text-align: left;
  }
}

@media only screen and (min-width: 750px) {
  .banner--content-align-right .banner__box {
    text-align: right;
  }

  .banner--content-align-left .banner__box {
    text-align: left;
  }

  .banner--content-align-left.banner--desktop-transparent .banner__box,
  .banner--content-align-right.banner--desktop-transparent .banner__box,
  .banner--medium.banner--desktop-transparent .banner__box {
    max-width: 68rem;
  }
}

@media screen and (max-width: 749px) {
  .banner--small.banner--mobile-bottom:not(.banner--adapt) .banner__media,
  .banner--small.banner--stacked:not(.banner--mobile-bottom):not(.banner--adapt) > .banner__media {
    height: 28rem;
  }

  .banner--medium.banner--mobile-bottom:not(.banner--adapt) .banner__media,
  .banner--medium.banner--stacked:not(.banner--mobile-bottom):not(.banner--adapt) > .banner__media {
    height: 34rem;
  }

  .banner--large.banner--mobile-bottom:not(.banner--adapt) .banner__media,
  .banner--large.banner--stacked:not(.banner--mobile-bottom):not(.banner--adapt) > .banner__media {
    height: 39rem;
  }

  .banner--small:not(.banner--mobile-bottom):not(.banner--adapt) .banner__content {
    min-height: 28rem;
  }

  .banner--medium:not(.banner--mobile-bottom):not(.banner--adapt) .banner__content {
    min-height: 34rem;
  }

  .banner--large:not(.banner--mobile-bottom):not(.banner--adapt) .banner__content {
    min-height: 39rem;
  }
}

@media screen and (min-width: 750px) {
  .banner {
    flex-direction: row;
  }

  .banner--small:not(.banner--adapt) {
    min-height: 42rem;
  }

  .banner--medium:not(.banner--adapt) {
    min-height: 56rem;
  }

  .banner--large:not(.banner--adapt) {
    min-height: 72rem;
  }

  .banner__content.banner__content--top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .banner__content.banner__content--top-center {
    align-items: flex-start;
    justify-content: center;
  }

  .banner__content.banner__content--top-right {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .banner__content.banner__content--middle-left {
    align-items: center;
    justify-content: flex-start;
  }

  .banner__content.banner__content--middle-center {
    align-items: center;
    justify-content: center;
  }

  .banner__content.banner__content--middle-right {
    align-items: center;
    justify-content: flex-end;
  }

  .banner__content.banner__content--bottom-left {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .banner__content.banner__content--bottom-center {
    align-items: flex-end;
    justify-content: center;
  }

  .banner__content.banner__content--bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 749px) {
  .banner:not(.banner--stacked) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .banner--stacked {
    height: auto;
  }

  .banner--stacked .banner__media {
    flex-direction: column;
  }
}

.banner__media {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.banner__media-half {
  width: 50%;
}

.banner__media-half + .banner__media-half {
  right: 0;
  left: auto;
}

@media screen and (max-width: 749px) {
  .banner--stacked .banner__media-half {
    width: 100%;
  }

  .banner--stacked .banner__media-half + .banner__media-half {
    order: 1;
  }
}

@media screen and (min-width: 750px) {
  .banner__media {
    height: 100%;
  }
}

.banner--adapt,
.banner--adapt_image.banner--mobile-bottom .banner__media:not(.placeholder) {
  height: auto;
}

@media screen and (max-width: 749px) {
  .banner--mobile-bottom .banner__media,
  .banner--stacked:not(.banner--mobile-bottom) .banner__media {
    position: relative;
  }

  .banner--stacked.banner--adapt .banner__content {
    height: auto;
  }

  .banner:not(.banner--mobile-bottom):not(.email-signup-banner) .banner__box {
    background: transparent;
    --color-foreground: 255, 255, 255;
    --color-button: 255, 255, 255;
    --color-button-text: 0, 0, 0;
  }

  .banner:not(.banner--mobile-bottom) .banner__box {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .banner:not(.banner--mobile-bottom) .button--secondary {
    --color-button: 255, 255, 255;
    --color-button-text: 255, 255, 255;
    --alpha-button-background: 0;
  }

  .banner--stacked:not(.banner--mobile-bottom):not(.banner--adapt)
    .banner__content {
    position: absolute;
    height: auto;
  }

  .banner--stacked.banner--adapt:not(.banner--mobile-bottom) .banner__content {
    max-height: 100%;
    overflow: hidden;
    position: absolute;
  }

  .banner--stacked:not(.banner--adapt) .banner__media {
    position: relative;
  }

  .banner::before {
    display: none !important;
  }

  .banner--stacked .banner__media-image-half {
    width: 100%;
  }
}

.banner__content {
  padding: 0;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

@media screen and (min-width: 750px) {
  .banner__content {
    padding: 5rem;
  }

  .banner__content--top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .banner__content--top-center {
    align-items: flex-start;
    justify-content: center;
  }

  .banner__content--top-right {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .banner__content--middle-left {
    align-items: center;
    justify-content: flex-start;
  }

  .banner__content--middle-center {
    align-items: center;
    justify-content: center;
  }

  .banner__content--middle-right {
    align-items: center;
    justify-content: flex-end;
  }

  .banner__content--bottom-left {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .banner__content--bottom-center {
    align-items: flex-end;
    justify-content: center;
  }

  .banner__content--bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 749px) {
  .banner--mobile-bottom:not(.banner--stacked) .banner__content {
    order: 2;
  }

  .banner:not(.banner--mobile-bottom) .field__input {
    background-color: transparent;
  }
}

.banner__box {
  padding: 4rem 3.5rem;
  position: relative;
  height: fit-content;
  align-items: center;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
  z-index: 1;
}

@media screen and (min-width: 750px) {
  .banner--desktop-transparent .banner__box {
    background: transparent;
    --color-foreground: 255, 255, 255;
    --color-button: 255, 255, 255;
    --color-button-text: 0, 0, 0;
    max-width: 89rem;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .banner--desktop-transparent .button--secondary {
    --color-button: 255, 255, 255;
    --color-button-text: 255, 255, 255;
    --alpha-button-background: 0;
  }

  .banner--desktop-transparent .content-container:after {
    display: none;
  }
}

@media screen and (max-width: 749px) {
  .banner--mobile-bottom::after,
  .banner--mobile-bottom .banner__media::after {
    display: none;
  }
}

.banner::after,
.banner__media::after {
  content: '';
  position: absolute;
  top: 0;
  background: #000000;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.banner__box > * + .banner__text {
  margin-top: 1.5rem;
}

@media screen and (min-width: 750px) {
  .banner__box > * + .banner__text {
    margin-top: 2rem;
  }
}

.banner__box > * + * {
  margin-top: 1rem;
}

.banner__box > *:first-child {
  margin-top: 0;
}

@media screen and (max-width: 749px) {
  .banner--stacked .banner__box {
    width: 100%;
  }
}

@media screen and (min-width: 750px) {
  .banner__box {
    width: auto;
    max-width: 71rem;
    min-width: 45rem;
  }
}

@media screen and (min-width: 1400px) {
  .banner__box {
    max-width: 90rem;
  }
}

.banner__heading {
  margin-bottom: 0;
}

.banner__box .banner__heading + * {
  margin-top: 1rem;
}

.banner__buttons {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 45rem;
  word-break: break-word;
}

@media screen and (max-width: 749px) {
  .banner--content-align-mobile-right .banner__buttons--multiple {
    justify-content: flex-end;
  }

  .banner--content-align-mobile-center .banner__buttons--multiple > * {
    flex-grow: 1;
    min-width: 22rem;
  }
}

@media screen and (min-width: 750px) {
  .banner--content-align-center .banner__buttons--multiple > * {
    flex-grow: 1;
    min-width: 22rem;
  }

  .banner--content-align-right .banner__buttons--multiple {
    justify-content: flex-end;
  }
}

.banner__box > * + .banner__buttons {
  margin-top: 2rem;
}
